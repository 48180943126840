@use 'src/app/shared/styles/variables';

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html,
body {
  height: 100%;
  color: variables.$zs-gray-90;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: variables.$zs-gray-100;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: variables.$bg-track;
}

::-webkit-scrollbar-thumb {
  background: variables.$bg-scroll;
  border-radius: 2px;

  &:hover {
    background: variables.$zs-gray-50;
  }
}

.place-items-center-absolute {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.page-header {
  background: variables.$white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  z-index: 1;
}

.page-title {
  color: variables.$zs-gray-100;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 32px;
}

.page-subtitle {
  color: variables.$zs-gray-60;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 21px;

  &.page-subtitle-italic {
    font-style: italic;
  }
}

.text-muted {
  color: variables.$zs-gray-60;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.section-title-1 {
  color: variables.$zs-gray-100;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.section-title-2 {
  color: variables.$zs-gray-100;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.section-title-3 {
  color: variables.$zs-gray-100;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.page-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.notification__container {
  display: flex;
  align-items: center;

  i.notification__icon {
    font-size: 20px;
    margin-right: 0.5rem;
  }
}
