$primary-color: #27a6a4; // zs-teal-60
$zs-teal-70: #2d8b93;
$zs-teal-highlight: #27a6a41a;

$secondary-color: #ff9900;

$error-red: #e65428;

$zs-gray: #fafafa;
$zs-gray-10: #f0f0f0;
$zs-gray-20: #dedcde;
$zs-gray-30: #b2b0b6;
$zs-gray-40: #9c9aa1;
$zs-gray-60: #716e79;
$zs-gray-50: #87848d;
$zs-gray-80: #454250;
$zs-gray-90: #2f2c3c;
$zs-gray-100: #1a1628;

$white: #fff;

$bg-scroll: #c4c4c4;
$bg-track: #efefef;

$link-hover: #0a7e92;
$error-red: #e65428;
$disabled: #f5f5f5;
$disabled-border: #d9d9d9;
