.d-flex {
  display: flex;
}
.justify-content-between {
  display: flex;
  justify-content: space-between;
}
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.fw-bold {
  font-weight: 700;
}
.fw-normal {
  font-weight: 400;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.lh-19 {
  line-height: 19px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.w-80 {
  width: 80vw;
}
.ml-200 {
  margin-left: 200px;
}
.ml-20 {
  margin-left: 20px;
}
.fl-right {
  float: right;
}
.m-20 {
  margin: 20px;
}
.align-center {
  align-items: center;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}
.justify-space-evenly {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center;
}
.w-100 {
  width: 100%;
}
.mb-5 {
  margin-bottom: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-24 {
  margin-bottom: 24px;
}
